







































































































































































































































































import Base from '@/mixins/Base.vue';
import { IBlogPost, IBlogPostResponse } from '@/interfaces/blogpost';

const component = Base.extend({
    components: {
    },

    data() {
        return {
            currentLanguage: 'en',
            apiKey: process.env.VUE_APP_TINY_MCE_API_KEY,
            blogPost: undefined as any | IBlogPost,
        };
    },

    mounted() {
        this.getBlogPost();
    },
    methods: {
        getBlogPost() : void{
            this.get<IBlogPostResponse>(`blog/posts/${this.$route.params.id}`).then(({ data }) => {
                this.blogPost = data.data;
            });
        },
        saveBlogPost() : void{
            this.put<IBlogPostResponse>(`blog/posts/${this.$route.params.id}`, {
                ...this.blogPost,
                category_id: this.blogPost.category ? this.blogPost.category.id : null,
            }).then(({ data }) => {
                this.blogPost = data.data;
                this.$emit('success', { message: `post ${data.data.id} updated` });
            });
        },
        uploadBlogPostPicture(): void {
            const formData = new FormData();

            if (this.blogPost.picture) {
                formData.append('picture', this.blogPost.picture as any);
            }

            this.post<IBlogPostResponse>(`blog/posts/${this.$route.params.id}/picture`, formData)
                .then(({ data }) => {
                    this.blogPost.picture_url = data.data.picture_url;
                    this.$emit('success', { message: 'picture updated' });
                })
                .finally(() => {
                    this.blogPost.picture = null;
                });
        },
    },
});

export default component;
