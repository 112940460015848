var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"level"},[_c('div',{staticClass:"level-left"},[_c('h1',{staticClass:"level-item"},[_c('sp-link',{staticClass:"mr-3",attrs:{"tag":"b-button","to":{name:'blog.posts.list'},"icon-left":"arrow-left"}}),( ! _vm.blogPost)?_c('b-skeleton',{attrs:{"size":"is-medium","animated":"","width":"300"}}):[_c('h1',{staticClass:"title"},[_vm._v(" Edit "+_vm._s(_vm._f("truncate")(_vm.blogPost.title,25))+" ")])]],2)]),(_vm.blogPost)?_c('div',{staticClass:"level-right"},[_c('sp-link',{attrs:{"to":{name: 'blog.posts.view',params:{id: _vm.blogPost.id, slug: _vm.blogPost.slug}},"icon-left":"eye","type":"is-dark","outlined":"","tag":"b-button","disabled":_vm.isLoading}},[_vm._v(" View ")])],1):_vm._e()]),(_vm.blogPost)?_c('div',[_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passes = ref.passes;
var valid = ref.valid;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return passes(_vm.saveBlogPost)}}},[_c('div',{staticClass:"block"},[_c('div',{staticClass:"subtitle"},[_vm._v(" General ")]),_c('ValidationProvider',{attrs:{"immediate":"","rules":"required","name":'translations.title.'+_vm.currentLanguage,"tag":"b-field"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"horizontal":"","label":"Title","type":{'is-danger': errors[0]},"message":errors ? errors[0] : null}},[_c('b-field',[_c('b-input',{attrs:{"disabled":_vm.isLoading,"expanded":""},model:{value:(_vm.blogPost.translations.title[_vm.currentLanguage]),callback:function ($$v) {_vm.$set(_vm.blogPost.translations.title, _vm.currentLanguage, $$v)},expression:"blogPost.translations.title[currentLanguage]"}}),_c('b-select',{model:{value:(_vm.currentLanguage),callback:function ($$v) {_vm.currentLanguage=$$v},expression:"currentLanguage"}},_vm._l((_vm.$store.getters.languages),function(lang,l){return _c('option',{key:l,domProps:{"value":lang.code}},[_vm._v(" "+_vm._s(lang.name)+" ")])}),0)],1)],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"immediate":"","rules":"required","name":'translations.text.'+_vm.currentLanguage,"tag":"b-field"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"horizontal":"","label":"Text","type":{'is-danger': errors[0]},"message":errors ? errors[0] : null}},[_c('b-field',[_c('editor',{attrs:{"api-key":_vm.apiKey,"disabled":_vm.isLoading},model:{value:(_vm.blogPost.translations.text[_vm.currentLanguage]),callback:function ($$v) {_vm.$set(_vm.blogPost.translations.text, _vm.currentLanguage, $$v)},expression:"blogPost.translations.text[currentLanguage]"}})],1)],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"immediate":"","rules":"required","name":"category","tag":"b-field"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"horizontal":"","label":"Category","type":{'is-danger': errors[0]},"message":errors ? errors[0] : null}},[_c('sp-blog-category',{attrs:{"disabled":_vm.isLoading},model:{value:(_vm.blogPost.category),callback:function ($$v) {_vm.$set(_vm.blogPost, "category", $$v)},expression:"blogPost.category"}})],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"immediate":"","rules":"","name":"icon","tag":"b-field"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"horizontal":"","label":"Icon","type":{'is-danger': errors[0]},"message":errors ? errors[0] : null}},[_c('sp-icon',{attrs:{"disabled":_vm.isLoading},model:{value:(_vm.blogPost.icon),callback:function ($$v) {_vm.$set(_vm.blogPost, "icon", $$v)},expression:"blogPost.icon"}})],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"immediate":"","rules":"","name":"color","tag":"b-field"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"horizontal":"","label":"Color","type":{'is-danger': errors[0]},"message":errors ? errors[0] : null}},[_c('sp-color',{attrs:{"disabled":_vm.isLoading},model:{value:(_vm.blogPost.color),callback:function ($$v) {_vm.$set(_vm.blogPost, "color", $$v)},expression:"blogPost.color"}})],1)]}}],null,true)}),_c('b-field',{attrs:{"horizontal":"","label":"Picture"}},[_c('div',{staticClass:"media"},[(_vm.blogPost.picture_url)?_c('div',{staticClass:"media-left"},[_c('figure',{staticClass:"image is-128x128",style:({height: 'auto'})},[_c('img',{attrs:{"src":_vm.blogPost.picture_url,"alt":""}})])]):_vm._e(),_c('div',{staticClass:"media-content"},[_c('ValidationProvider',{attrs:{"immediate":"","rules":"","name":"picture","tag":"b-field"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"type":{'is-danger': errors[0]},"message":errors ? errors[0] : null}},[_c('b-upload',{staticClass:"file-label",attrs:{"disabled":_vm.isLoading},on:{"input":function($event){return _vm.uploadBlogPostPicture()}},model:{value:(_vm.blogPost.picture),callback:function ($$v) {_vm.$set(_vm.blogPost, "picture", $$v)},expression:"blogPost.picture"}},[_c('div',{staticClass:"button",attrs:{"disabled":_vm.isLoading}},[_c('b-icon',{attrs:{"icon":"upload"}}),_c('span',[_vm._v("Upload file")]),(_vm.blogPost.picture)?_c('span',{staticClass:"tag ml-2"},[_vm._v(" "+_vm._s(_vm.blogPost.picture.name)+" ")]):_vm._e()],1)]),(_vm.blogPost.picture_url)?_c('b-button',{attrs:{"disabled":_vm.isLoading},on:{"click":function($event){_vm.blogPost.picture_url = null}}},[_vm._v(" Remove ")]):_vm._e()],1)]}}],null,true)})],1)])]),_c('ValidationProvider',{attrs:{"immediate":"","rules":"","name":"is_published","tag":"b-field"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"horizontal":"","label":"Published","type":{'is-danger': errors[0]},"message":errors ? errors[0] : null}},[_c('b-switch',{attrs:{"disabled":_vm.isLoading},model:{value:(_vm.blogPost.is_published),callback:function ($$v) {_vm.$set(_vm.blogPost, "is_published", $$v)},expression:"blogPost.is_published"}})],1)]}}],null,true)})],1),_c('b-field',{attrs:{"horizontal":""}},[_c('b-button',{attrs:{"type":"is-primary","icon-left":"save","disabled":! valid || _vm.isLoading},on:{"click":function($event){return _vm.saveBlogPost()}}},[_vm._v(" "+_vm._s(_vm.$t('save'))+" ")])],1)],1)]}}],null,false,2556136057)})],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }